import  i18n  from '@/utils/lang/index.js';

let toTimeText = (timeStamp, simple) => {
	var dateTime = new Date(timeStamp)
	var currentTime = Date.parse(new Date()); //当前时间
	var timeDiff = currentTime - dateTime; //与当前时间误差
	var timeText = '';
	if (timeDiff <= 60000) { //一分钟内
		timeText = i18n.t('date.just');
	} else if (timeDiff > 60000 && timeDiff < 3600000) { 
		//1小时内
		timeText = Math.floor(timeDiff / 60000) + i18n.t('date.minutesago');
	} else if (timeDiff >= 3600000 && timeDiff < 86400000 && !isYestday(dateTime)) { 
		//今日
		timeText = formatDate(dateTime,'hh:mm');
	} else if (isYestday(dateTime)) { 
		//昨天
		timeText = i18n.t('date.yesterday') + formatDate(dateTime,'hh:mm'); 
	} else if (isYear(dateTime)) { 
		//今年
		timeText = formatDate(dateTime,simple?'dd/M':'dd/M hh:mm:ss');
	} else { 
		//不属于今年
		timeText = formatDate(dateTime,simple?'dd/M/yyyy':'dd/M/yyyy hh:mm:ss');
	}
	return timeText;
}

let isYestday = (date) => {
	var yesterday = new Date(new Date() - 1000 * 60 * 60 * 24);
	return yesterday.getYear() === date.getYear() &&
		yesterday.getMonth() === date.getMonth() &&
		yesterday.getDate() === date.getDate();
}

let isYear = (date) => {
	return date.getYear() === new Date().getYear();
}


//is_12h是否12小时制 默认是
let formatDate = (param, format,is_12h=true)=>{
	if (param === '' || !param) {
		return ''
	}
	var date = new Date(param)
	const map = {
		'y': date.getFullYear(),
		'M': date.getMonth() + 1, // 月份
		'd': date.getDate(), // 日
		'h': date.getHours(), // 小时
		'm': date.getMinutes(), // 分钟
		's': date.getSeconds(), // 秒
		'q': Math.floor((date.getMonth() + 3) / 3), // 季度
		'S': date.getMilliseconds() // 毫秒
	};
	let ampm  = ''
	format = format.replace(/([yMdhmsqS])+/g, (all, t) => {
		let v = map[t];
		if (v !== undefined) {
			if(t === 'h'&&is_12h){
				if(v >= 12){
					ampm ='PM'
					v = v-12
				}else{
					ampm ='AM'
				}
			}
			if (all.length > 1) {
				v = '0' + v;
				v = v.substr(v.length - all.length);
			}
			return v;
		} else if (t === 'y') {
			return (date.getFullYear() + '').substr(4 - all.length);
		}
		return all;
	});
	return format +' '+ ampm;
}

export{
	toTimeText,
	isYestday,
	isYear,
	formatDate
}